import React from "react"

import Layout from "../components/layout.component"
import Header from "../components/header.component"
import { graphql } from "gatsby"
import { BannerWithButtons } from "../components/common/banner.component"
import { Image } from "../components/common/image.component"
import { Html } from "../components/common/html.component"
import { Slices } from "../components/slices.component"
import { linkFragment } from "../link-resolver";
import SEO from "../components/common/seo.component"

const IndexPage = ({ data }) => {
  const page = data.prismic.allHome_pages.edges[0]
    if(page) {
        return (
          <Layout>
            <SEO
              title={page.node.page_title || page.node.title}
              description={page.node.description || page.node.text}
              keywords={page.node.keywords}
              image={page.node.preview_image}
              lang={page.node._meta.lang}
            />
              <BannerWithButtons
                navbar={<Header theme="dark"/>}
                text={page.node.content}
                backgroundImage={page.node.background_image}
                buttons={page.node.buttons}
                className="home-page-banner"
              >
                  <div className="home-header">
                      <div className="company-summary font-medium text-dark-blue text-left mx-auto mb-5">
                          <Html html={page.node.htmlBanner} />
                          <div className="row mb-n3">
                              {page.node.partners.map((partner, index) => (
                                <div key={`partner${index}`} className="col-12 col-md mb-3">
                                    <div className="img-company-summary mx-auto">
                                        <Image alt="img" image={partner.image}/>
                                    </div>
                                </div>
                              ))}
                          </div>
                      </div>
                  </div>
              </BannerWithButtons>
              <Slices body={page.node.body} />
          </Layout>
        )
    }
    return null;
}

IndexPage.fragments = [linkFragment];

export const query = graphql`
    query  {
        prismic {
            allHome_pages {
                edges {
                    node {
                        background_image
                        page_title
                        description
                        keywords {
                            keyword
                        }
                        preview_image
                        _meta {
                            uid
                            lang
                        }
                        buttons {
                            link {
                                ...link
                            }
                            link_text
                        }
                        content
                        partners {
                            image
                        }
                        htmlBanner
                        body {
                            ... on PRISMIC_Home_pageBodyGallery {
                                type
                                label
                                primary {
                                    anchor
                                    bgColor
                                    bgImage
                                    text
                                    title
                                }
                                fields {
                                    image
                                    text
                                    title
                                }
                            }
                            ... on PRISMIC_Home_pageBodyPricing_plans {
                                type
                                label
                                fields {
                                    price_units
                                    plan_price
                                    plan_name
                                    plan_features
                                    link_text
                                    link_style
                                    link {
                                        ...link
                                    }
                                    is_free_plan
                                }
                                primary {
                                    bg_image
                                    bg_color
                                    title
                                    text
                                }
                            }
                            ... on PRISMIC_Home_pageBodyText {
                                type
                                label
                                primary {
                                    text
                                    bg_color
                                    bg_image
                                }
                            }
                            ... on PRISMIC_Home_pageBodyFeature {
                                type
                                label
                                primary {
                                    bg_color
                                    bg_image
                                    text
                                }
                                fields {
                                    image
                                    link_style
                                    link_text
                                    text
                                    title
                                    link {
                                       ...link
                                    }
                                }
                            }
                            ...on PRISMIC_Home_pageBodyBlock_with_text_and_image4 {
                                label
                                type
                                primary  {
                                    bg_color
                                    bg_image
                                    min_height
                                    title
                                    text
                                    image
                                    link {
                                        ...link
                                    }
                                    link_style
                                    link_text
                                }
                            }
                            ... on PRISMIC_Home_pageBodyForm {
                                type
                                label
                                primary {
                                    bg_color
                                    bg_image
                                    form_script
                                    form_url
                                    text
                                }
                            }
                        }
                    }
                }
            }
        }
    }

`

export default IndexPage;
